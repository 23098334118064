import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './resources';

const configureI18n = () => {
    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            resources,

            supportedLngs: ['en', 'zh'],
            fallbackLng: 'en',
            debug: process.env.NODE_ENV !== 'production',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            detection: {
                order: [
                    'path',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'navigator',
                    'querystring',
                    'htmlTag',
                    'subdomain',
                ],
            },
        });

    return i18n;
};

export default configureI18n;
