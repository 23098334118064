export default {
    common: {
        test: 'test',
        lan: 'EN',
        cookiePolicy: `Playful Data Secret Reveal
        To provide you with more and better content, this website collects cookies for analysing purposes. Continue on browsing the website provides us consent to collect cookies. More information can be found in our <1>Privacy Policy</1>.。
    `,
        cookieComfirm: 'Got it',
    },
    brand: {
        walkr: 'Walkr',
        fortuneCity: 'Fortune City',
        plantnanny2: 'Plant Nanny ²',
        toDoAdventure: 'To-do Adventure',
    },
    entry: {
        lookingBack2021: 'Reveal the secret \n behind the Playful Data',
        selectionTitle: 'Have you tried any of the following Apps in 2021? ',
        multipleChoice: '(multiple choice)',
        continue: 'Next',
        noneOfThem: 'None of the above',
        enterFDID: 'Enter your FDID',
        foundFDIDInApp: 'Please visit Fourdesire App > Settings > \n to get your FDID',
        start: 'Reveal the secret',
        back: 'Return',
    },
    highFive: {
        highFive: `Give us a perfect high-five here on \n your phone screen.`,
        highFiveDesktop: `Give us a perfect high-five here \n click with your mouse.`,
        wellDone: 'Great!',
    },
    messages: {
        opening_npc_1:
            'It’s such great work you’ve done in 2021, thank you for another amazing year.',
        opening_npc_2: "High five! Let's take a look at what you've achieved in the past year.",
        opening_user_1: 'High Five!',
        withData_npc_1: "I've organized the 2021 data in a review.",
        withData_npc_2: 'It’s awesome work! Let’s view it together.',
        noData_npc_1_visitor:
            'Hi new friend! We didn’t have a chance to meet in 2021 so there lacks data.',
        noData_npc_1_login:
            'Hey! Old friend! Were you too busy and forgot about the app? It’s a shame that we didn’t get to share your year more…',
        noData_npc_2:
            'Though we can’t find your data, don’t despair! Take 30 seconds and join the Playful Quiz. You’ll have chances to win Fourdesire prizes including AirPods 3. Let us learn through playing and do better in 2022!',
        // noData_npc_3:
        //     'Though we can’t find your data, don’t despair! Take 30 seconds and join the Playful Quiz. You’ll have chances to win Fourdesire prizes including AirPods 3. Let us learn through playing and do better in 2022!',
        noData_npc_4:
            'Thinking back in the beginning of 2021, How were you planning your to-do list?',
        noData_user_1_a: 'Obsessive! Arrange everything into my list gave me a sense of security.',
        noData_user_1_b: 'Flexible! Wrote things down only when I thought of them.',
        noData_user_1_c: 'Work-life balance. I put both work and relaxing time in my list.',
        noData_user_1_d: 'A master of time management. I never had to use a to-do list!',
        noData_npc_5_a: 'Don’t forget to plan your relax time into the schedule, too!',
        noData_npc_5_b: 'Try to write down a New Year Resolution starting this year!',
        noData_npc_5_c: 'Work-life balance pro! You know how to have fun!',
        noData_npc_5_d: 'An expert in time management! Respect!',
        noData_npc_6:
            'Fun fact! Feb 2 2021 was the explorers’ peak day of productivity! A total of 8003 plans were completed on the day! Hurray! ',
        noData_npc_7: 'What exercises had you done during WFH?',
        noData_user_2_a: 'Ate snacks to exercise my mouth.',
        noData_user_2_b: 'Watched Youtube to exercise my eyes.',
        noData_user_2_c: 'Used Walkr to exercise with aliens.',
        noData_user_2_d: 'No exercise at all, I was a couch potato.',
        noData_npc_8_a: 'You’d be the next champion in weight-lifting contests with teeth!',
        noData_npc_8_b: 'This year you have made yourself healthier!',
        noData_npc_8_c: 'Aliens told us that they love to walk with you, too!',
        noData_npc_8_d: 'The birth of a couch potato!',
        noData_npc_9_visitor:
            'Do you know? The total distance Captains from Walkr walked in 2021 can travel around the U.S. 3 times in a week! Crazy!',
        noData_npc_9_login:
            'Do you know? The total distance Captains from Walkr walked can travel around the U.S. 3 times in a week! Magnificent!',
        noData_npc_10_visitor:
            'With the energy accumulated by evreyone together… 1.87 million planets have been explored in the Walkr Universe!',
        noData_npc_10_login:
            'With the energy accumulated by evreyone together… 1.87 million planets have been explored in the Walkr Universe!',
        noData_npc_11:
            'Imagine being in a tropical city and there’s a power cut, What will you do?',
        noData_user_3_a: 'Close all doors and windows to keep the air-conditioned cool air in.',
        noData_user_3_b: 'Bring out my own generator and start air-conditioner immediately.',
        noData_user_3_c: 'Meditate to cool myself down mentally.',
        noData_user_3_d: 'Do nothing. Just believe that human will survive.',
        noData_npc_12_a: 'Environmental friendly and smart strategy!',
        noData_npc_12_b: 'That’s a useful gadget to keep at home!',
        noData_npc_12_c: 'A world saver! Polar bears say thank you.',
        noData_npc_12_d: 'A strong believer with mind power!',
        noData_npc_13:
            'Fortune City in 2021 — the Mayors had recorded 2.5 million expenses in a week!',
        noData_npc_14: 'Converting to coins, it would fill 230,000 piggy banks!',
        noData_npc_15: 'Facing a hurricane approaching, what’s your first thought?',
        noData_user_4_a: 'Finally we’ll have more water.',
        noData_user_4_b: 'I can foresee an increase in daily supplies and foods afterwards…',
        noData_user_4_c: 'What!? A hurricane!?',
        noData_user_4_d: 'I still have to work from home and can’t have a day off…',
        noData_npc_16_a: '',
        noData_npc_16_b: 'Don’t worry too much! The supply will go back to normal soon.',
        noData_npc_16_c: 'Don’t be too busy to keep an eye on the news!',
        noData_npc_16_d: 'Stay home and be safe is the best strategy!',
        noData_npc_17:
            'Don’t worry only about the draughts but ignore the dehydration of your body!',
        noData_npc_18_visitor:
            'The water Gardeners drank in 2021 together reached 1.75 billion ml, it can fill 168 Statues of Liberty! Join us!',
        noData_npc_18_login:
            'The water Gardeners drank in 2021 together reached 1.75 billion ml, it can fill 168 Statues of Liberty!',
        noData_npc_19: 'Let’s imagine your 2022, it will be like…',
    },
    result: {
        calaulating: 'Analyzing…',
        'city-title-drink': 'Beverage Bay',
        'city-title-empty': 'City of Nothing',
        'city-title-entertain': 'Holiday Resort',
        'city-title-food': 'Cuisine Paradise',
        'city-title-housing': 'Living Paradise',
        'city-title-income': 'Fortune City',
        'city-title-medical': 'Medical Center',
        'city-title-misc': 'Grocery Shangri-La',
        'city-title-shopping': 'Shopping Mecca',
        'city-title-threec': 'Electric Land',
        'city-title-traffic': 'Traffic Hub',
        flourish_lv_1: 'Garden Newbie',
        flourish_lv_3: 'Garden Rookie',
        flourish_lv_5: 'Garden Intern',
        flourish_lv_10: 'Rookie Gardener',
        flourish_lv_15: 'Assistant Gardener',
        flourish_lv_20: 'Senior Gardener',
        flourish_lv_25: 'Garden Keeper',
        flourish_lv_30: 'Forest Keeper',
        flourish_lv_35: 'Forest Protector',
        flourish_lv_40: 'Assistant Principal',
        flourish_lv_45: 'Associate Principal',
        flourish_lv_50: 'Principal',
        'first.lab': 'Planet Researcher',
        'planet.3': 'Rookie',
        'planet.6': 'Intern',
        'planet.9': 'Tourist',
        'planet.12': 'Traveler',
        'planet.15': 'Ranger',
        'planet.18': 'Pilot',
        'planet.21': 'Pioneer',
        'planet.24': 'Adventurer',
        'planet.27': 'Cadet',
        'planet.30': 'Seacher',
        'planet.33': 'Navigator',
        'planet.36': 'Researcher',
        'planet.39': 'Explorer',
        'planet.42': 'Guardian',
        'planet.45': 'Commander',
        'planet.48': 'Captain',
        'asteroid.1': 'Asteroid Discover',
        'asteroid.5': 'Asteroid Explorer',
        'asteroid.13': 'Asteroid Expert',
        'asteroid.28': 'Asteroid Researcher',
        'prosperity.10': 'Assistant Administrator',
        'prosperity.20': 'Senior Administrator',
        'prosperity.30': 'Director of the Bureau',
        'prosperity.50': 'Rookie City Secretary',
        'prosperity.100': 'City Secretary',
        'prosperity.250': 'Senior City Secretary',
        'prosperity.500': 'Secretary General',
        'prosperity.750': 'Urban Manager',
        'prosperity.1000': 'Urban Director',
        'prosperity.2500': 'Municipal Adviser',
        'prosperity.5000': 'Municipal Minister',
        'prosperity.7500': 'Deputy Mayor',
        'prosperity.10000': 'Mayor',
        theme_3: 'Novice Traveler',
        theme_5: 'Adventure Backpacker',
        theme_10: 'Professional Adventurer',
        theme_15: 'Master Adventurer',
        theme_20: 'The Great Adventurer',
        coin_100: 'First Pot of GoCoins',
        coin_1000: 'GoCoin Hunter',
        coin_5000: 'GoCoin Collector',
        coin_10000: 'GoCoin Banker',
        coin_30000: 'GoCoin Afficionado',
        coin_50000: 'GoCoin Tycoon',
        walkr_a1: `You walked the most steps \non {{ date }} with {{ value }} steps!`,
        walkr_a2: 'Your highest record is \ncreating {{ value }} DFR in a \nsingle week',
        walkr_a3: '{{ month }} you’ve taken part \nin {{ value }} legends, \nrecord breaking',
        walkr_a4: 'you still became \na {{ name }} on {{ date }}, \nwe are proud of you!',
        walkr_a4_vowel: 'you still became \nan {{ name }} on {{ date }}, \nwe are proud of you!',
        fortuneCity_a1: 'You have recorded {{ value }} \nexpenses on {{ date }}!',
        fortuneCity_a2: 'In a single month you’ve \nattracted {{ value }} citizen to visit.',
        fortuneCity_a3:
            'In {{ month }} you built {{ value }} \nbuildings and broke your \nown record.',
        fortuneCity_a4: '{{ date }} you became \na {{ name }}, \nwe are proud of you!',
        fortuneCity_a4_vowel: '{{ date }} you became \nan {{ name }}, \nwe are proud of you!',
        plantnanny2_a1: 'You have drunk {{ value }} cups \nof water on {{ date }}',
        plantnanny2_a2: 'In a single week you’ve \ndrunk {{ value }} litres of water',
        plantnanny2_a3:
            'In {{ month }} you had the \nhighest record of \n{{ value }} plants harvested,',
        plantnanny2_a4:
            'You made the achiement and \nbecame a {{ name }} \non {{ date }}, we are proud of you!',
        plantnanny2_a4_vowel:
            'You made the achiement and \nbecame an {{ name }} \non {{ date }}, we are proud of you!',
        toDoAdventure_a1: '{{ date }} is your most \nproductive day.',
        toDoAdventure_a2: 'You have accumulated \n{{ value }} islands in \na single week.',
        toDoAdventure_a3:
            'In {{ month }} you had the \nhighest record of winning \n{{ value }} wonders',
        toDoAdventure_a4: '{{ date }} you’ve officially \nbecome {{ name }},\nwe are proud of you!',
        toDoAdventure_a4_vowel:
            '{{ date }} you’ve officially \nbecome {{ name }},\nwe are proud of you!',
        userTop: `Thank you for joining us and reviewing 2021.

                    From these sparkling moments, we found that you are a person with a playful mind!
                    
                    You will use the most entertaining way to stay healthy in the coming year. 
                    Why not add these to your New Year resolution?
                    `,
        walkrTop: `Thank you for joining us and reviewing 2021.

                    We saw your energetic DNA in these sparkling moments! 
                    
                    You will fall in love with the magnificent adventures, and explore more beauty in the new year.
                    
                    Why not add these to your New Year resolution?`,
        fortuneCityTop: `Thank you for joining us and reviewing 2021.

                        From these sparkling moments we’ve spotted your brilliant mind. 
                        
                        You will have fun trying all possible new ways to achieve success in the new year.

                        Why not add these to your New Year resolution?`,
        plantnanny2Top: `Thank you for joining us and reviewing 2021.

                        From these sparkling moments, we found that you are a person with a playful mind!
                        
                        You will use the most entertaining way to stay healthy in the coming year. 

                        Why not add these to your New Year resolution?`,
        toDoAdventureTop: `Thank you for joining us and reviewing 2021.
                        From these sparkling moments we have found that you value quality and love organising your life. 
                        
                        You love the little extra helps for you to bring the ideal lifestyle into reality.

                        Why not add these to your New Year resolution?`,
        pressScreen: 'Long press on your screen',
        screenshot: 'Capture a screenshot',
        downloadImage: `Share your result to join
                        the GIVEAWAY!`,
        luckyDraw: `Share this picture in your IG Story and @4desire, If you add the webpage link <1>(copy link)</1> and invite your friends, you'll be able to enroll in lottery for prizes including AirPods 3!`,
        copySuccess: 'Copied',
        checkAllPrize: ' Click to see what\n prizes there are',
        walkrAppName: 'Walkr - Gamified Fitness App',
        fortuneCityAppName: 'Fortune City - Expense Tracker',
        plantnanny2AppName: 'Plant Nanny² Water Tracker Log ',
        toDoAdventureAppName: 'To-Do Adventure: Habit Tracker',
        downloadBtn: 'Play for a better you',
        luckyDrawTitle: 'Guide to Enrol in Lottery',
        luckyDrawDetail: `Share your review in IG story and you’ll have a chance to enter the lottery for prizes including AirPods 3. Let the prizes accompany you to escape the boring daily routines and create an exciting life!

        To win the prizes:
        1. Share your review to IG story and set the account to Public 
        2. Tag the official account @4desire and add link to this webpage
        3. Message us to log for lottery`,
        prizeA: 'Playful Box',
        prizeADetail:
            '【AirPods 3】, 【Walkr Captain limited badge】, 【Fortune City pillow set】, 【Plant Nanny² water-proof stickers】',
        prizeB: 'Player’s Box',
        prizeBDetail:
            '【Apple gift card】, 【Playful Design】Book with author’s autograph, 【Walkr Planet phone stand】, 【Fortune City coin purse】, 【Plant Nanny² postcards】',
        prizeC: 'Good Habits Bag',
        prizeCDetail:
            '【Plant Nanny² drinking plan】, 【Fortune City finance officer plan】, 【Walkr Asteroid exploration plan】, 【To Do Adventure brand new themes】, 【High Five award bag】',
        priceCNotes:
            'Including 150 《Plant Nanny²》seeds, 150《Fortune City》diamonds,150 《To Do Adventure》Gocoin, and 150《Walkr》 energy cubes.',
        unit: '',
        notice: `- Multiple accounts, multiple entries to the campaign, fabrication or registration with stolen information, and other abnormalities will cause elimination from the lottery without further notification. Fourdesire has the right to not provide prizes in the said situations. 

        - Fourdesire keeps related rights to this campaign including the right to explain, amend, adjust, and end the activities. Details, changes, and other conditions should follow the official statement on the website. 
        
        - Prizes from this campaign cannot be exchanged to cash or other awards. `,
        downloadAndShare: 'Share your result to \njoin the GIVEAWAY',
        playAgain: 'Try again',
    },
};
