import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import 'normalize.css';
import './global.css';

import store from 'models/store';
import routes from 'routes';
import configureI18n from 'i18n';

import App from 'layouts/App';

configureI18n();

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App>
                <Suspense fallback={<></>}>{renderRoutes(routes)}</Suspense>
            </App>
        </Router>
    </Provider>,
    document.getElementById('app'),
);
