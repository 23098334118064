import { configureStore } from '@reduxjs/toolkit';

import reducer from 'models/reducers';

export default configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
            const { createLogger } = require('redux-logger');

            return getDefaultMiddleware().concat(createLogger());
        }

        return getDefaultMiddleware();
    },
});
