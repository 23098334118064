import { useWindowSize } from 'react-use';
import { isMobileOnly } from 'react-device-detect';
import classnames from 'classnames';

import Logo from 'images/logo.inline.svg';

import bg from 'images/bg.png';

import styles from './index.css';

const App: React.FC = ({ children }) => {
    const { width, height } = useWindowSize();

    return (
        <div
            className={classnames(styles.app, isMobileOnly && styles.mobileDevice)}
            style={{ width, height, backgroundImage: !isMobileOnly ? `url(${bg})` : '' }}
        >
            {(isMobileOnly || width <= 414) && children}
            {!isMobileOnly && width > 414 && (
                <>
                    <Logo className={classnames(styles.logo, styles.left)} />
                    <div className={styles.container}>
                        <div className={styles.view}>{children}</div>
                    </div>
                    <Logo className={classnames(styles.logo, styles.right)} />

                    <div className={styles.copyright}>© Fourdesire. All Rights Reserved.</div>
                </>
            )}
        </div>
    );
};

export default App;
