import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
    const history = useHistory();
    history.push('/');

    return null;
};

export default NotFound;
