import qs from 'qs';

const { API_ENDPOINT, FOURDESIRE_API_ENDPOINT } = process.env;

// eslint-disable-next-line @typescript-eslint/ban-types
export const generateUrl = (url: string, params?: object, endpoint?: string) => {
    const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

    const URL =
        paramsString !== ''
            ? `${endpoint || API_ENDPOINT}${url}?${paramsString}`
            : `${endpoint || API_ENDPOINT}${url}`;

    return URL;
};

interface ApiOptionProperty {
    // eslint-disable-next-line @typescript-eslint/ban-types
    headers?: object;
    method?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
}

export const wrapFetch = async (
    url: string,
    options: ApiOptionProperty = { headers: {} },
    params = {},
) => {
    const URL = generateUrl(
        url,
        {
            ...params,
        },
        API_ENDPOINT,
    );

    const headers = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...options.headers,
    });

    const result = await fetch(URL, { ...options, headers });

    return result.json();
};

export const wrapFourdesireFetch = async (
    url: string,
    options: ApiOptionProperty = { headers: {} },
    params = {},
) => {
    const URL = generateUrl(
        url,
        {
            ...params,
        },
        FOURDESIRE_API_ENDPOINT,
    );

    const headers = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...options.headers,
    });

    const result = await fetch(URL, { ...options, headers });

    return result.json();
};
