export const color = (colorCode: string) => {
    const { palette } = process.env;

    // @ts-ignore
    return palette[colorCode] || colorCode;
};

export const numberWithCommas = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRandomItem = (array: any[]) => array[Math.floor(Math.random() * array.length)];

export const getRandomApp = () =>
    getRandomItem(['walkr', 'fortuneCity', 'plantnanny2', 'toDoAdventure']);
