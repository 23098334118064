import { createReducer } from '@reduxjs/toolkit';

import { getRandomApp } from 'utils/helper';

import { setView, setUserType, setAppType, fetchAppData, resetData } from './actions';

import type { View, UserType, AppType, AppData } from './types';

export interface State {
    view: View;
    userType: UserType;
    fdid?: string;
    appType: AppType;
    appOrder: AppType[];
    appData: {
        walkr: AppData;
        fortuneCity: AppData;
        plantnanny2: AppData;
        toDoAdventure: AppData;
    };
}

const defaultAppData = {
    walkr: {
        q1: 0,
        q1Date: '',
        q2: 0,
        q2Week: '',
        q3: 0,
        q3Month: '',
        q4: 0,
        q5: '',
        q5Date: '',
    },
    fortuneCity: {
        q1: 0,
        q1Date: '',
        q2: 0,
        q2Week: '',
        q3: 0,
        q3Month: '',
        q4: 0,
        q5: '',
        q5Date: '',
    },
    plantnanny2: {
        q1: 0,
        q1Date: '',
        q2: 0,
        q2Week: '',
        q3: 0,
        q3Month: '',
        q4: 0,
        q5: '',
        q5Date: '',
    },
    toDoAdventure: {
        q1: 0,
        q1Date: '',
        q2: 0,
        q2Week: '',
        q3: 0,
        q3Month: '',
        q4: 0,
        q5: '',
        q5Date: '',
    },
};

const initialState: State = {
    view: 'opening',
    userType: 'visitor',
    fdid: '',
    appType: 'walkr',
    appOrder: [],
    appData: defaultAppData,
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setView, (state, action) => {
            state.view = action.payload;
        })
        .addCase(setUserType, (state, action) => {
            state.userType = action.payload;
        })
        .addCase(setAppType, (state, action) => {
            state.appType = action.payload;
        })
        .addCase(fetchAppData.fulfilled, (state, action) => {
            state.fdid = action.payload.fdid;

            if (action.payload.fdidExist) {
                state.userType = action.payload.order.length > 0 ? 'withData' : 'noData';
            } else {
                state.userType = 'visitor';
            }

            state.appType = action.payload.appType;
            state.appOrder = action.payload.order;
            state.appData = action.payload.data;

            state.view = 'conversion';
        })
        .addCase(fetchAppData.rejected, (state) => {
            state.fdid = '';
            state.userType = 'visitor';
            state.appType = getRandomApp();
            state.appOrder = [];
            state.appData = defaultAppData;
        })
        .addCase(resetData, (state) => {
            state.userType = 'visitor';
            state.fdid = '';
            state.appType = 'walkr';
            state.appOrder = [];
            state.appData = defaultAppData;
        });
});

export default reducer;
