export default {
    common: {
        test: '測試',
        lan: '中',
        cookiePolicy: `為提供您更多優質的內容，本網站使用cookies分析技術。若繼續閱覽本網站內容，即表示您同意我們使用
            cookies，關於更多cookies資訊請閱讀我們的
            <1>
                隱私權政策
            </1>。
        `,
        cookieComfirm: '我知道了',
    },
    brand: {
        walkr: 'Walkr',
        fortuneCity: '記帳城市',
        plantnanny2: '植物保姆 ²',
        toDoAdventure: '記事探險',
    },
    entry: {
        lookingBack2021: '你的 2021 年度回顧',
        selectionTitle: '今年 2021 年有玩過哪些 App 嗎？',
        multipleChoice: '可複選',
        continue: '繼續',
        noneOfThem: '都沒玩過～',
        enterFDID: '輸入 FDID',
        foundFDIDInApp: '請開啟 Fourdesire App，並提供「設定」\n頁面的 Fourdesire ID',
        start: '開始揭秘',
        back: '返回',
    },
    highFive: {
        highFive: `對著『手機』螢幕擊掌\n來個完美的 HIGH FIVE 吧！`,
        highFiveDesktop: `『點擊滑鼠』\n來個完美的 HIGH FIVE 吧！`,
        wellDone: '太棒了！',
    },
    messages: {
        opening_npc_1: '2021 的你辛苦了，謝謝你又努力地走過一年',
        opening_npc_2: '現在就和我們擊掌一起回顧今年的大小事吧！',
        opening_user_1: 'High Five!',
        withData_npc_1: '我整理了你 2021年的回顧紀錄',
        withData_npc_2: '好厲害！一起來看看吧',
        noData_npc_1_visitor: '哎呀！原來是新朋友！2021 還沒有機會認識你',
        noData_npc_1_login:
            '哎呀！原來是老朋友！2021 忙碌的你是不是都忘了開啟 App 啊好可惜～沒有一起走過 2021 這一年ＱＱ',
        noData_npc_2:
            '雖然沒辦法偵測到你2021年的玩心數據，但沒關係～ 現在參加 30 秒的玩心測驗，同樣有機會贏得品牌限量周邊與加碼好禮 AirPod 3 陪你在 2022 玩出更好的自己！',
        // noData_npc_3: '靠著大家步行累積的能量... Walkr 宇宙中共有 1,870,877 顆星球被探索完成！',
        noData_npc_4: '回想 2021 初始之際，你是怎麼規劃待辦清單的呢？',
        noData_user_1_a: '資訊控必備，排好排滿才有安全感！',
        noData_user_1_b: '自由放飛，想到什麼再來安排',
        noData_user_1_c: '兼顧工作與生活，先把休息放入清單',
        noData_user_1_d: '時間管理大師舉手！完全不用排',
        noData_npc_5_a: '也別忘了把休息加入待辦清單唷～',
        noData_npc_5_b: '今年不妨試試新年計畫吧！',
        noData_npc_5_c: '原來是懂平衡的生活玩樂家！',
        noData_npc_5_d: '時間管理界的第一把交椅～失敬失敬',
        noData_npc_6:
            '偷偷跟你說～\n2021 年 2 月 2 日是探險家的生產力巔峰唷！總共有 8003 個計畫順利的完成了～好棒！',
        noData_npc_7: '那居家工作期間你做了哪些運動？',
        noData_user_2_a: '用零食幫嘴巴進行重訓',
        noData_user_2_b: '認真看 Youtube 宅家動',
        noData_user_2_c: '用 Walkr 和外星人一起動滋動',
        noData_user_2_d: '陷入沙發一動也不動',
        noData_npc_8_a: '哇！下一屆嘴巴舉重大賽冠軍就是你',
        noData_npc_8_b: '謝謝今年的你也讓自己變健康了～',
        noData_npc_8_c: '外星人說他也很喜歡和你一起動動 ❤️',
        noData_npc_8_d: '沙發馬鈴薯生成！',
        noData_npc_9_visitor: '你知道嗎？\n2021 Walkr 艦長們走出的距離相當於環島 2787 次（驚',
        noData_npc_9_login:
            '你知道嗎？\n2021年 Walkr 艦長們走出的距離相當於一週環島 2,787 次，好驚人！',
        noData_npc_10_visitor:
            '靠著大家步行累積的能量... Walkr 宇宙中共有 1,870,877 顆星球被探索完成！',
        noData_npc_10_login: '靠著大家步行累積的能量...Walkr 宇宙中共有 187 萬顆星球被探索完成！',
        noData_npc_11: '還記得夏天停電的崩潰瞬間嗎？你的直覺反應是？',
        noData_user_3_a: '門窗緊閉，珍惜冷氣',
        noData_user_3_b: '拿出家用發電機，自給自足',
        noData_user_3_c: '心靜自然涼，救救北極熊',
        noData_user_3_d: '心懷善念，努力用愛發電',
        noData_npc_12_a: '客家精神發動！',
        noData_npc_12_b: '原來你家有這個酷東西',
        noData_npc_12_c: '環保大使 4 你，北極熊感謝你',
        noData_npc_12_d: '謝謝你讓我們知道用愛可以拯救世界',
        noData_npc_13: '九月停電冷氣強制斷電，為大家的荷包稍稍止血，也是另類的小確幸～',
        noData_npc_14:
            '和你分享 2021 記帳城市市長最高曾在一週內記下 250 萬筆帳，相當於 23 萬個小豬撲滿存下的錢錢！',
        noData_npc_15: '面對颱風來襲，你會最先脫口說出？',
        noData_user_4_a: '讚！終於不用限水啦！',
        noData_user_4_b: '嗚嗚菜價又要變高了',
        noData_user_4_c: '哪尼？有颱風！？',
        noData_user_4_d: 'WFH 居然沒颱風假？',
        noData_npc_16_a: '',
        noData_npc_16_b: '別擔心，過一陣子就會恢復正常啦～',
        noData_npc_16_c: '珍愛生命，請關注新聞消息！',
        noData_npc_16_d: '嗚～只好乖乖待在家安全工作',
        noData_npc_17: '別只關心水庫缺不缺水，忽略身體發出的缺水警訊～',
        noData_npc_18_visitor:
            '植物保姆的園長們 2021 一起喝下 17.5 億公升的水可以填滿 1,640 座 101 大樓呢！快加入補水行列吧～',
        noData_npc_18_login:
            '植物保姆的園長們 2021 一起喝下 17.5 億公升的水，可以填滿 1,640 座 101 大樓呢！',
        noData_npc_19: '等等！我可以預測 2022 年的你，會怎麼度過了！',
    },
    result: {
        calaulating: '計算中...',
        'city-title-drink': '豪飲之都',
        'city-title-empty': '空無一物',
        'city-title-entertain': '度假勝地',
        'city-title-food': '美食之都',
        'city-title-housing': '居住天堂',
        'city-title-income': '財富之都',
        'city-title-medical': '醫療重鎮',
        'city-title-misc': '雜貨天堂',
        'city-title-shopping': '消費天堂',
        'city-title-threec': '電器城',
        'city-title-traffic': '交通樞紐',
        flourish_lv_1: '花園新人',
        flourish_lv_3: '花園菜鳥',
        flourish_lv_5: '花園實習生',
        flourish_lv_10: '新手園丁',
        flourish_lv_15: '助理園丁',
        flourish_lv_20: '資深園丁',
        flourish_lv_25: '花園守護者',
        flourish_lv_30: '守林人',
        flourish_lv_35: '花園管理者',
        flourish_lv_40: '助理園長',
        flourish_lv_45: '副園長',
        flourish_lv_50: '園長',
        'first.lab': '星球研究員',
        'planet.3': '太空菜鳥',
        'planet.6': '太空實習生',
        'planet.9': '太空旅遊者',
        'planet.12': '太空旅行者',
        'planet.15': '太空浪人',
        'planet.18': '太空駕駛員',
        'planet.21': '星際先鋒',
        'planet.24': '星際冒險家',
        'planet.27': '星際推進者',
        'planet.30': '星際搜查者',
        'planet.33': '星際領航員',
        'planet.36': '星際研究員',
        'planet.39': '銀河探索者',
        'planet.42': '銀河守護者',
        'planet.45': '銀河指揮官',
        'planet.48': '艦長',
        'asteroid.1': '小行星發現者',
        'asteroid.5': '小行星探索者',
        'asteroid.13': '小行星專家',
        'asteroid.28': '小行星研究員',
        'prosperity.10': '助理管理員',
        'prosperity.20': '資深管理員',
        'prosperity.30': '管理局長',
        'prosperity.50': '菜鳥市政秘書',
        'prosperity.100': '市政秘書',
        'prosperity.250': '資深市政秘書',
        'prosperity.500': '秘書長',
        'prosperity.750': '都市發展經理',
        'prosperity.1000': '都市發展總監',
        'prosperity.2500': '市政顧問',
        'prosperity.5000': '市政大臣',
        'prosperity.7500': '副市長',
        'prosperity.10000': '市長',
        theme_3: '初心旅人',
        theme_5: '冒險背包客',
        theme_10: '自由探險家',
        theme_15: '歷險行家',
        theme_20: '暢遊浪人',
        coin_100: '第一桶金',
        coin_1000: '勾勾幣獵人',
        coin_5000: '尋金達人',
        coin_10000: '淘金大師',
        coin_30000: '勾勾幣鑑賞家',
        coin_50000: '勾勾幣大亨',
        walkr_a1: '今年你竟然在 {{ date }}\n走了 {{ value }} 步路',
        walkr_a2: '單週最高紀錄打造了\n{{ value }} 個 DFR',
        walkr_a3: '曾在 {{ month }} 月最高紀錄\n參加 {{ value }} 場傳說',
        walkr_a4: '在 {{ date }} 正式成為\n{{ name }}\n真為你感到驕傲！',
        fortuneCity_a1: '今年你竟然在 {{ date }}\n記下 {{ value }} 筆帳',
        fortuneCity_a2: '單月最高紀錄吸引\n{{ value }} 個小市民來訪',
        fortuneCity_a3: '在 {{ month }} 月最高紀錄\n建造 {{ value }} 個建築',
        fortuneCity_a4: '在 {{ date }} 正式成為\n{{ name }}\n真為你感到驕傲！',
        plantnanny2_a1: '今年你竟然在 {{ date }}\n喝下 {{ value }} 杯水',
        plantnanny2_a2: '單週最高紀錄喝下\n{{ value }} 毫升的水',
        plantnanny2_a3: '曾在 {{ month }} 月最高紀錄\n收獲 {{ value }} 個小植物 ',
        plantnanny2_a4: '在 {{ date }} 正式成為\n{{ name }}\n真為你感到驕傲！',
        toDoAdventure_a1: '今年 {{ date }} 是你\n完成最多計畫的一天！',
        toDoAdventure_a2: '單週最高紀錄累積\n{{ value }} 個島嶼',
        toDoAdventure_a3: '在 {{ month }} 月最高紀錄\n累積 {{ value }} 座奇觀',
        toDoAdventure_a4: '在 {{ date }} 正式成為\n{{ name }}\n真為你感到驕傲！',
        userTop: `嘿嘿～

                偷看了你的紀錄，今年成功養成許多好習慣呢
                
                2022 也要繼續和 Fourdesire 一起玩出更好的自己！
                
                \\ 快去領取你的年度回顧吧 /`,
        walkrTop: `哇～ 感謝你一起回顧 2021 的點點滴滴

                    從這些重要時刻，嗅到你充滿活力的DNA囉！
                    
                    相信你會愛上華麗的冒險，在新的一年探索更多美景

                    預測 2022 的你將會....`,
        fortuneCityTop: `哇～ 感謝你一起回顧 2021 的點點滴滴

                    從這些重要時刻，見識到你精明的腦袋
                    
                    相信你樂於嘗試有趣的新方法，在新的一年累積成就

                    預測 2022 的你將會....`,
        plantnanny2Top: `哇～ 感謝你一起回顧 2021 的點點滴滴

                        從這些重要時刻，發現你是個充滿玩心的人耶！
                        
                        相信你會用充滿樂趣的方式，在新的一年保持健康

                        預測 2022 的你將會....`,
        toDoAdventureTop: `哇～ 感謝你一起回顧 2021 的點點滴滴

                        從這些重要時刻，察覺你是重視質感計畫控
                        
                        相信你會喜歡療癒小幫手，在新的一年實踐理想生活

                        預測 2022 的你將會....`,
        pressScreen: '對螢幕長按下載圖片',
        screenshot: '使用螢幕截圖下載圖片',
        downloadImage: '下載圖片，分享抽獎去！',
        luckyDraw: `分享圖片到 IG 限時動態
                    1. 標記 ＠4desire
                    2. 附上本活動網頁連結 <1>(複製連結)</1>
                    請朋友一起玩就能抽 AirPods 3 等大禮包！`,
        copySuccess: '複製成功',
        checkAllPrize: '點我看所有獎品',
        walkrAppName: 'Walkr - 口袋裡的銀河冒險',
        fortuneCityAppName: '記帳城市 - 用每筆收支，建造你的城市',
        plantnanny2AppName: '植物保姆² 喝水提醒',
        toDoAdventureAppName: '記事探險：待辦事項時間管理工具',
        downloadBtn: '玩出更好的你',
        luckyDrawTitle: '抽獎辦法',
        luckyDrawDetail: `動動手指分享專屬結果頁至 IG 限時動態，抽 AirPods 3 等大禮包，讓它們陪伴你逃離 2021 的苦悶生活，創造自己的美好生活！

                        獲取辦法
                        1. 公開分享專屬結果至限時動態
                        2. 標記 Fourdesire 官方 IG @4desire 並附上本活動網頁連結
                        3. 前往「私訊匣」回傳抽獎資料`,
        prizeA: '玩心禮盒',
        prizeADetail:
            '玩心禮盒包含【AirPods 3】、【Walkr艦長限定徽章】、【記帳城市雙人抱枕組】、【植物保姆鎖水貼紙】',
        prizeB: '玩家禮盒',
        prizeBDetail:
            '玩家禮盒包含【Apple gift card】、【玩心設計】親簽書、【Walkr星球手機支架】、【記帳城市必收零錢包】、【植物保姆喝水明信片】',
        prizeC: '好習慣生活禮包',
        prizeCDetail:
            '好習慣生活禮包包含【植物保姆飲水計畫】、【記帳城市財務長計畫】、【Walkr小行星探索計劃】、【記事探險全新場景主題】、【High Five 遊戲獎勵禮包】',
        priceCNotes:
            'High Five 遊戲獎勵禮包含 150 《植物保姆》種子、《記帳城市》150 鑽石、《記事探險》150 勾勾幣、《Walkr》150 能量方塊',
        unit: '位',
        notice: `-如發現註冊多重帳號、重複參加活動或偽造、盜用他人資料註冊或其他異常狀況者，本公司有權不予贈點，並不經通知直接取消其參加資格

                        -本公司保有活動之解釋、修改、調整、終止等相關權利，其詳細辦法、變更事項或未盡事宜則以網站公告為主！
                        
                        -本活動之獎品不得折抵現金或兌換其他物品
                        
                        -領獎時需要憑限時動態截圖領獎`,
        downloadAndShare: '下載圖片，分享抽獎去',
        playAgain: '再玩一次',
    },
};
