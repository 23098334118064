import globalReducer from 'models/global/reducer';

import type { State as GlobalState } from 'models/global/reducer';

export interface State {
    global: GlobalState;
}

export default {
    global: globalReducer,
};
