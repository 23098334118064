import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { wrapFetch, wrapFourdesireFetch } from 'utils/api';

import { getRandomItem } from 'utils/helper';

import { View, UserType, AppType, AppData } from './types';

export const setView = createAction<View>('global/setView');

export const setUserType = createAction<UserType>('global/setUserType');

export const setAppType = createAction<AppType>('global/setAppType');

export const resetData = createAction('global/resetData');

const APP_ID_MAP: Record<AppType, number> = {
    walkr: 2,
    plantnanny2: 1,
    fortuneCity: 3,
    toDoAdventure: 4,
};

export const fetchAppData = createAsyncThunk(
    'global/fetchAppData',
    async ({
        fdid,
        app: { walkr, fortuneCity, plantnanny2, toDoAdventure },
    }: {
        fdid: string;
        app: Record<AppType, boolean>;
    }) => {
        const defaultAppData: AppData = {
            q1: 0,
            q1Date: '',
            q2: 0,
            q2Week: '',
            q3: 0,
            q3Month: '',
            q4: 0,
            q5: '',
            q5Date: '',
        };
        const appData = {
            walkr: defaultAppData,
            fortuneCity: defaultAppData,
            plantnanny2: defaultAppData,
            toDoAdventure: defaultAppData,
        };

        const appList: AppType[] = [];

        let fdidExist = false;

        if (walkr) {
            const walkrResult = await wrapFourdesireFetch(
                `/personal?fdid_product=${fdid}_${APP_ID_MAP.walkr}`,
                {
                    method: 'GET',
                },
            );

            if (walkrResult.success === 'true') {
                fdidExist = true;

                appList.push('walkr');
                appData.walkr = {
                    q1: walkrResult.result.info.a,
                    q1Date: walkrResult.result.info.a_date
                        ? walkrResult.result.info.a_date.replace('2021-', '').replace('-', '/')
                        : '',
                    q2: walkrResult.result.info.b,
                    q2Week: walkrResult.result.info.b_week
                        ? walkrResult.result.info.b_week.replace('-', '/')
                        : '',
                    q3: walkrResult.result.info.c,
                    q3Month: walkrResult.result.info.c_month
                        ? walkrResult.result.info.c_month.replace('-', '/')
                        : '',
                    q4: walkrResult.result.info.d,
                    q5: walkrResult.result.info.f,
                    q5Date: walkrResult.result.info.f_date
                        ? walkrResult.result.info.f_date
                              .split('-')
                              .filter((_, index: number) => index !== 0)
                              .join('/')
                        : '',
                };
            }
        }

        if (plantnanny2) {
            const plantnanny2Result = await wrapFourdesireFetch(
                `/personal?fdid_product=${fdid}_${APP_ID_MAP.plantnanny2}`,
                {
                    method: 'GET',
                },
            );

            if (plantnanny2Result.success === 'true') {
                fdidExist = true;

                appList.push('plantnanny2');
                appData.plantnanny2 = {
                    q1: plantnanny2Result.result.info.a,
                    q1Date: plantnanny2Result.result.info.a_date
                        ? plantnanny2Result.result.info.a_date
                              .replace('2021-', '')
                              .replace('-', '/')
                        : '',
                    q2: plantnanny2Result.result.info.b,
                    q2Week: plantnanny2Result.result.info.b_week
                        ? plantnanny2Result.result.info.b_week.replace('-', '/')
                        : '',
                    q3: plantnanny2Result.result.info.c,
                    q3Month: plantnanny2Result.result.info.c_month
                        ? plantnanny2Result.result.info.c_month.replace('-', '/')
                        : '',
                    q4: plantnanny2Result.result.info.d,
                    q5: plantnanny2Result.result.info.f,
                    q5Date: plantnanny2Result.result.info.f_date
                        ? plantnanny2Result.result.info.f_date
                              .split('-')
                              .filter((_, index: number) => index !== 0)
                              .join('/')
                        : '',
                };
            }
        }

        if (fortuneCity) {
            const fortuneCityResult = await wrapFourdesireFetch(
                `/personal?fdid_product=${fdid}_${APP_ID_MAP.fortuneCity}`,
                {
                    method: 'GET',
                },
            );

            if (fortuneCityResult.success === 'true') {
                fdidExist = true;

                appList.push('fortuneCity');
                appData.fortuneCity = {
                    q1: fortuneCityResult.result.info.a,
                    q1Date: fortuneCityResult.result.info.a_date
                        ? fortuneCityResult.result.info.a_date
                              .replace('2021-', '')
                              .replace('-', '/')
                        : '',
                    q2: fortuneCityResult.result.info.b,
                    q2Week: fortuneCityResult.result.info.b_week
                        ? fortuneCityResult.result.info.b_week.replace('-', '/')
                        : '',
                    q3: fortuneCityResult.result.info.c,
                    q3Month: fortuneCityResult.result.info.c_month
                        ? fortuneCityResult.result.info.c_month.replace('-', '/')
                        : '',
                    q4: fortuneCityResult.result.info.d,
                    q5: fortuneCityResult.result.info.f,
                    q5Date: fortuneCityResult.result.info.f_date
                        ? fortuneCityResult.result.info.f_date
                              .split('-')
                              .filter((_, index: number) => index !== 0)
                              .join('/')
                        : '',
                };
            }
        }

        if (toDoAdventure) {
            const toDoAdventureResult = await wrapFourdesireFetch(
                `/personal?fdid_product=${fdid}_${APP_ID_MAP.toDoAdventure}`,
                {
                    method: 'GET',
                },
            );

            if (toDoAdventureResult.success === 'true') {
                fdidExist = true;

                appList.push('toDoAdventure');
                appData.toDoAdventure = {
                    q1: toDoAdventureResult.result.info.a,
                    q1Date: toDoAdventureResult.result.info.a_date
                        ? toDoAdventureResult.result.info.a_date
                              .replace('2021-', '')
                              .replace('-', '/')
                        : '',
                    q2: toDoAdventureResult.result.info.b,
                    q2Week: toDoAdventureResult.result.info.b_week
                        ? toDoAdventureResult.result.info.b_week.replace('-', '/')
                        : '',
                    q3: toDoAdventureResult.result.info.c,
                    q3Month: toDoAdventureResult.result.info.c_month
                        ? toDoAdventureResult.result.info.c_month.replace('-', '/')
                        : '',
                    q4: toDoAdventureResult.result.info.d,
                    q5: toDoAdventureResult.result.info.f,
                    q5Date: toDoAdventureResult.result.info.f_date
                        ? toDoAdventureResult.result.info.f_date
                              .split('-')
                              .filter((_, index: number) => index !== 0)
                              .join('/')
                        : '',
                };
            }
        }

        if (fdidExist) {
            wrapFetch('/fdid', {
                method: 'POST',
                body: JSON.stringify({
                    fdid,
                }),
            });
        }

        return {
            fdid,
            fdidExist,
            appType: getRandomItem(appList),
            order: appList,
            data: appData,
        };
    },
);
